import React from "react"
import styled from "styled-components"
import Modal from "./Modal"

import loadingSpinner from "../imgs/loading-spinner.svg"

import { MyModalBody } from "./ModalHelpers"

const StyledModal = styled(MyModalBody)`
  .container {
    text-align: center;
  }

  em {
    font-size: 14px;
  }
`

export default function CoinbaseWaitingModal(props) {
  const { isOpen, onHide, paymentMethod = "" } = props

  return (
    <Modal show={isOpen} onHide={onHide}>
      <StyledModal>
        <div className="container">
          <img src={loadingSpinner} alt="Loading" />
          <p>
            <strong>
              Waiting for {paymentMethod && paymentMethod.toLowerCase()} payment
              to be completed...
            </strong>

            <br />
            <br />
            <em>
              Transaction in progress. Confirmation usually takes 10 minutes, please wait.If you don't wish to wait, you may close
              this window and come back later to complete your purchase
              manually. Your funds will be stored securely in your account.
            </em>
          </p>
        </div>
      </StyledModal>
    </Modal>
  )
}
