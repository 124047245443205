import { get } from "lodash"

const genericError =
  "Payment could not be processed. Please make sure your card support 3D secure or contact your card issuer"

export const nmiErrorCodeMap = {
  TRANSACTION_STATUS_N:
    "Payment could not be processed. Please make sure your card support 3D secure or contact your card issuer",
  TRANSACTION_STATUS_U:
    "Payment could not be processed. Please make sure your card support 3D secure or contact your card issuer",
  TRANSACTION_STATUS_R:
    "Payment could not be verified. Please check your payment details and try again.",
  TRANSACTION_UNKNOWN:
    "An error occurred. Check your payment details and try again.",
  TRANSACTION_TIMED_OUT: "Timeout. Check your connection before trying again.",
}

export const getNMIFormattedError = error => {
  try {
    if (error && error.code) {
      return get(nmiErrorCodeMap, error.code, genericError)
    }
    if (error && error.refId) {
      if (error.message && error.message.includes("Transaction Timed Out")) {
        return get(nmiErrorCodeMap, "TRANSACTION_TIMED_OUT", genericError)
      }
      if (
        error.message &&
        error.message.includes("Invalid Formatted Message")
      ) {
        return get(nmiErrorCodeMap, "TRANSACTION_UNKNOWN", genericError)
      }
      return genericError
    }
    if (error && error.type && error.type === "gatewayError" && !error.refId) {
      return get(nmiErrorCodeMap, "TRANSACTION_STATUS_R", genericError)
    }
    return genericError
  } catch (e) {
    return genericError
  }
}
