/* eslint-disable no-console */
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { get } from "lodash"
import Modal from "./Modal"
import loadingSpinner from "../imgs/loading-spinner.svg"
import closeIcon from "../imgs/close.svg"
import authStore from "../stores/auth"

import { MyModalBody } from "./ModalHelpers"
import { toastError } from "../utils/myToasts"
import { getNMIFormattedError } from "../utils/nmiHelpers"

const StyledModal = styled(MyModalBody)`
  .container {
    min-height: 600px;
    padding: 0;
    overflow: hidden;
    position: relative;
    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    iframe {
      min-height: 600px;
    }
    .close-btn-wrapper {
      display: flex;
      justify-content: space-between;
      .close-btn {
        cursor: pointer;
      }
    }
    .email-input {
      width: 100%;
      padding: 10px;
      margin-top: 10px;
      text-align: left;
      border-radius: 2px;
      border: 1px solid #cccccc;
    }
    .button {
      width: 100%;
      text-align: center;

      box-sizing: border-box;
      border-radius: 4px;

      font-size: 14px;
      line-height: 16px;
      font-weight: 700;

      padding: 12px;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        opacity: 0.8;
        text-decoration: none;
      }
      &:disabled {
        background: #b0b0b0;
        // transform: scale(0.95);
      }
    }
    .button--continue {
      color: #fff;
      background-color: rgb(102, 122, 244);
      border: 1px solid transparent;
    }
  }
`

const PayWithCardModal = props => {
  const { isOpen, onHide, onSuccess, amount } = props
  const [loading, setLoading] = useState(true)
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [hasCardName, setHasCardName] = useState(false)
  const [token, setToken] = useState(false)
  const [threeDS, setThreeDS] = useState(null)
  const [threeDSInterface, setThreeDSInterface] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const email = get(authStore, "state.user.email", "email not found")

  useEffect(() => {
    // Initialize and listen collect token
    window.CollectJS.startPaymentRequest()
    window.CollectJS.configure({
      variant: "lightbox",
      callback: e => {
        setToken(e.token)
        setLoading(false)
      },
    })

    // Initialize 3DS Gateway
    initialize3DSGateway()

    // Unmount 3DS mounted iframe
    return () => threeDSInterface && threeDSInterface.unmount()
  }, [])

  const initialize3DSGateway = () => {
    if (!threeDS) {
      const gateway = window.Gateway.create(process.env.NMI_GATEWAY_KEY)
      const threeDSSecure = gateway.get3DSecure()
      setThreeDS(threeDSSecure)
    }
  }

  const hideLoader = () => {
    setLoading(false)
  }

  const handleHide = () => {
    threeDSInterface && threeDSInterface.unmount()
    onHide({ paymentSuccess })
  }

  const onSubmit = () => {
    setHasCardName(true)
    setLoading(true)
    const options = {
      paymentToken: token,
      currency: "USD",
      amount: amount,
      firstName,
      lastName,
      email,
    }
    let threeDSecureInterface
    threeDSecureInterface = threeDS.createUI(options)
    setThreeDSInterface(threeDSecureInterface)
    threeDSecureInterface.start("#threeDSMountPoint")
    threeDSecureInterface.on("challenge", function(e) {
      console.log(e)
      setTimeout(hideLoader, 2000)
      // hideLoader()
    })

    threeDSecureInterface.on("complete", function(e) {
      setPaymentSuccess(true)
      setLoading(true)
      onSuccess({ paymentToken: token, ...e })
      threeDSecureInterface.unmount()
    })

    threeDSecureInterface.on("failure", function(e) {
      // const message = get(e, "message", genericError)
      const message = getNMIFormattedError(e)
      toastError(message)
      threeDSecureInterface.unmount()
      handleHide()
    })

    threeDSecureInterface.on("error", function(e) {
      // const message = get(e, "message", genericError)
      const message = getNMIFormattedError(e)
      toastError(message)
      threeDSecureInterface.unmount()
      handleHide()
    })
  }

  return (
    <Modal show={isOpen} onHide={handleHide}>
      <StyledModal>
        <div className="container">
          {loading && (
            <div className="loading">
              <img src={loadingSpinner} alt="spinner" />
            </div>
          )}
          <div className="close-btn-wrapper">
            <div style={{ fontWeight: "bold", fontSize: "20px" }}>
              Pay with card
            </div>
            <span className="close-btn" onClick={handleHide}>
              <img src={closeIcon} alt="close" />
            </span>
          </div>
          {!loading && (
            <div>
              {!hasCardName && (
                <div>
                  <div className="small-container">
                    <p style={{ marginBottom: "0px", fontSize: "14px" }}>
                      Your bank requires further verification. This a two step
                      process. First, enter your first and last name used in the
                      card:
                    </p>
                    <input
                      type="text"
                      className="email-input"
                      placeholder="First name"
                      name="firstName"
                      data-event="focus"
                      data-multiline={true}
                      data-effect="solid"
                      onChange={e => setFirstName(e.target.value)}
                    />
                    <input
                      type="text"
                      className="email-input"
                      placeholder="Last name"
                      name="lastName"
                      data-event="focus"
                      data-multiline={true}
                      data-effect="solid"
                      onChange={e => setLastName(e.target.value)}
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <button
                      disabled={
                        loading ||
                        !firstName ||
                        firstName.length === 0 ||
                        !lastName ||
                        lastName.length === 0
                      }
                      className="button button button--continue"
                      onClick={onSubmit}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          <div id="threeDSMountPoint"></div>
        </div>
      </StyledModal>
    </Modal>
  )
}
export default PayWithCardModal
